import React from "react"
import {
  BsBookHalf,
  BsBook,
  // BsMicMuteFill,
  BsPeopleCircle,
} from "react-icons/bs"
import {
  FaNewspaper,
  FaRegNewspaper,
  FaVideo,
  FaRegFileVideo,
} from "react-icons/fa"
import { GiAudioCassette } from "react-icons/gi"
import { RiAlbumLine } from "react-icons/ri"
import { MdDateRange } from "react-icons/md"

const links = [
  {
    page: "Fordítás",
    label: "Könyvek",
    url: `/translation/books/1`,
    icon: <BsBookHalf className="icon" />,
  },
  {
    page: "Fordítás",
    label: "Évenkénti bontás",
    url: `/translation/booksyear/1`,
    icon: <MdDateRange className="icon" />,
  },

  {
    page: "Fordítás",
    label: "Magazinok",
    url: `/translation/magazines/1`,
    icon: <FaNewspaper className="icon" />,
  },

  {
    page: "Fordítás",
    label: "Szerzők",
    url: `/translation/authors/1`,
    icon: <BsPeopleCircle className="icon" />,
  },

  {
    page: "Írás",
    label: "Könyvek",
    url: `/writing/books/1`,
    icon: <BsBook className="icon" />,
  },
  {
    page: "Írás",
    label: "Magazinok",
    url: `/writing/magazines/1`,
    icon: <FaRegNewspaper className="icon" />,
  },
  {
    page: "Hanganyag",
    label: "",
    url: `/audio/1`,
    icon: [
      <RiAlbumLine className="icon" key="RiAlbumLine" />,
      <GiAudioCassette className="icon" key="GiAudioCassette" />,
    ],
  },
  // {
  //   page: "Hanganyag",
  //   label: "CD",
  //   url: `/audio/cds/1`,
  //   icon: <RiAlbumLine className="icon" />,
  // },
  // {
  //   page: "Hanganyag",
  //   label: "Kiadatlan",
  //   url: `/audio/bootlegs/1`,
  //   icon: <BsMicMuteFill className="icon" />,
  // },
  {
    page: "Mozgókép",
    label: "",
    url: `/video/1`,
    icon: [
      <FaRegFileVideo className="icon" key="FaRegFileVideo" />,
      <FaVideo className="icon" key="FaVideo" />,
    ],
  },
  // {
  //   page: "Mozgókép",
  //   label: "Koncertek",
  //   url: `/video/concerts/1`,
  //   icon: <FaVideo className="icon" />,
  // },
]

export default links
