/**
 * Write-only the password as cookie
 */
import React, { useState } from "react"
import { setSessionPassword } from "../utils/utils"
import styled from "styled-components"
import { Link } from "gatsby"

const PasswordProtect = () => {
  const [password, setPassword] = useState("")

  const onSubmit = event => {
    event.preventDefault()
    setSessionPassword(password)
    window.location.reload() // eslint-disable-line
  }

  return (
    <Wrapper className="section">
      <div className="section-center">
        <h2>Üdvözöllek</h2>
        <h4>Add meg a jelszót!</h4>

        <form onSubmit={onSubmit}>
          <input
            name="password"
            type="password"
            value={password}
            onChange={event => setPassword(event.target.value)}
          />
          <button type="submit" className="btn">
            Tovább
          </button>
        </form>

        <Link to="/" classname="">
          Vissza a főoldalra
        </Link>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  min-height: 100vh;
  background: var(--clr-dark-8);

  .section-center {
    padding-top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    form {
      input {
        background: var(--clr-light-5);
        width: 100%;
        height: 48px;
        border-radius: 4px;
      }

      .btn {
        width: 100%;
        height: 48px;
        font-size: 1.2rem;
        letter-spacing: 5px;
      }
    }

    a {
      margin-top: 2rem;
      background: transparent;
      border: 2px solid var(--clr-light-5);
      padding: 0.7rem 1rem;
      text-transform: uppercase;
      letter-spacing: 5px;
      color: var(--clr-light-8);
      font-size: 1rem;
      cursor: pointer;
      transition: var(--transition);

      border-radius: 4px;
    }

    a:hover {
      background: var(--clr-light-5);
      color: var(--clr-dark-3);
      border: 2px solid var(--clr-dark-8);
    }
  }
`

export default PasswordProtect
