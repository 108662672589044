import React from "react"
import { createGlobalStyle } from "styled-components"
import { GatsbyProvider } from "./src/context/context"
import "@fontsource/lato"
import "@fontsource/lora"
import "@fontsource/special-elite"

// provider

// import { GatsbyProvider } from "./src/context/context"
const GlobalStyle = createGlobalStyle`

/* New main colors form hero:

Innen:
http://www.iklimt.com/

Prussian Blue - #0B3C5D rgb(11,60,93)   hsl(204.1,78.8%,20.4%)
Sky Blue - #328CC1  
Gold Leaf - #D9B310
Ivory Black - #1D2731


Ez legjobb kikopizni az árnyalatokat közvetlenül hsl-ben!
https://www.colorhexa.com/84060b


https://palettegenerator.com/

https://www.w3schools.com/colors/colors_hexadecimal.asp
*/




:root {
  /* dark shades of primary PRUSSIAN BLUE color*/
  --clr-primary-1: hsl(204,76.9%,5.1%);
  --clr-primary-2: hsl(204,77.8%,8.8%);
  --clr-primary-3: hsl(204.7,78.5%,12.7%);
  --clr-primary-4: hsl(203.6,78.6%,16.5%);
  /* primary main color */
  --clr-primary-5: hsl(204.1,78.8%,20.4%);
  /* rgba opacity background csak az alábbival működik*/
  --clr-primary-5-rgb: rgb(11,60,93);
  /* lighter shadddes of primary color */
  --clr-primary-6: hsl(204.5,79%,24.3%);
  --clr-primary-7: hsl(203.9,79%,28%);
  --clr-primary-8: hsl(204.2,79.1%,32%);
  --clr-primary-9: hsl(204.2,79.1%,35.7%);
  --clr-primary-10: hsl(204,79.2%,39.6%);

  
   /* dark shades of secondary GOLD LEAF color*/
  --clr-secondary-1: hsl(48.7,85.8%,30.4%);
  --clr-secondary-2: hsl(48.8,86.2%,34.1%);
  --clr-secondary-3: hsl(48.9,86.5%,37.8%);
  --clr-secondary-4: hsl(48.6,86%,42%);
  /* secondary main color */
  --clr-secondary-5: hsl(48.7,86.3%,45.7%);
  /* lighter shades of secondary color */
  --clr-secondary-6: hsl(48.7,86.5%,49.4%);
  --clr-secondary-7: hsl(48.6,86.5%,53.5%);
  --clr-secondary-8: hsl(48.5,86.2%,57.3%);
  --clr-secondary-9: hsl(48.8,85.9%,61%);
  --clr-secondary-10: hsl(48.8,86.6%,64.9%);
 
  
    /* dark shades of tertiary BLUE SKY color*/
  --clr-tertiary-1: hsl(202.3,58.8%,32.4%);
  --clr-tertiary-2: hsl(202.2,58.7%,36.1%);
  --clr-tertiary-3: hsl(202.5,58.8%,40%);
  --clr-tertiary-4: hsl(202,58.7%,43.7%);
  /* tertiary main color */
  --clr-tertiary-5: hsl(202.2,58.8%,47.6%);
  /* lighter shades of tertiary color */
  --clr-tertiary-6: hsl(202.3,58.7%,51.6%);
  --clr-tertiary-7: hsl(202.4,58.8%,55.3%);
  --clr-tertiary-8: hsl(202.1,58.7%,59.2%);
  --clr-tertiary-9: hsl(202.2,58.7%,62.9%);
  --clr-tertiary-10: hsl(202.4,58.6%,66.9%);
  
  
  /* dark - IVORY BLACK */
  --clr-dark-1: hsl(204,26.3%,3.7%);
  --clr-dark-2: hsl(210,26.3%,7.5%);
  --clr-dark-3: hsl(212,25.4%,11.6%);
  /* primary dark color*/
  --clr-dark-4: hsl(210,25.6%,15.3%);
  /* lighter shades of dark  color */
  --clr-dark-5: hsl(208.8,25.8%,19%);
  --clr-dark-6: hsl(210,25.4%,23.1%);
  --clr-dark-7: hsl(210.9,25.5%,26.9%);
  --clr-dark-8: hsl(210,25.6%,30.6%);
  --clr-dark-9: hsl(210,26.1%,34.5%);
  --clr-dark-10: hsl(210,25.5%,38.4%);

/* dark primary opacity */
/*
https://stackoverflow.com/questions/59906693/why-cant-i-use-rgba-in-css-variable
*/
  --clr-dark-4-rgb: 29,39,49;
  --clr-dark-opacity: rgba(var(--clr-dark-4-rgb), 0.85);



  /* dark shades of tint IVORY BLACK */
  --clr-light-1: hsl(210,25.4%,53.7%);
  --clr-light-2: hsl(210,25.9%,57.6%);
  --clr-light-3: hsl(209.4,25.9%,61.4%);
  --clr-light-4: hsl(209.3,25.4%,65.3%);
  /* primary light  color*/
  --clr-light-5: hsl(210,25.3%,69%);
  /* lighter shades of light color */
  --clr-light-6: hsl(210,26.1%,72.9%);
  --clr-light-7: hsl(210,25.4%,76.9%);
  --clr-light-8: hsl(208.8,25.3%,80.6%);
  --clr-light-9: hsl(208.6,26.6%,84.5%);
  --clr-light-10: hsl(212,25.4%,88.4%);


  --clr-white: #fff;
  --clr-black: #000;

/*
button colors:  

heading colors:

card colors:

*/



  --ff-primary: "Lora", serif;
  --ff-secondary: "Lato", sans-serif;
  --ff-monospace: "Special Elite", monospace;


  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: var(--ff-secondary);
  background: var(--clr-dark-10);
  color: var(--clr-light-5);
  line-height: 1.5;
  font-size: 0.875rem;
  scroll-behavior: smooth;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
img {
  width: 100%;
  display: block;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  /* text-transform: capitalize; */
  line-height: 1.25;
  margin-bottom: 0.75rem;
  font-family: var(--ff-primary);
  font-weight: 400;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-dark-3);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 3.5rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    ${"" /* font-size: 1.75rem; */}
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

.btn {
  text-transform: uppercase;
  background: var(--clr-primary-5);
  color: var(--clr-light-9);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 400;
  transition: var(--transition);
  font-size: 0.875rem;
  ${"" /* border: 2px solid transparent; */}
  border: 1px solid var(--clr-primary-1);
  cursor: pointer;
  ${"" /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); */}
  border-radius: var(--radius);
}
.btn:hover {
  background: var(--clr-secondary-5);
  color: var(--clr-dark-1);
  border: 1px solid var(--clr-secondary-1);
}




/* section */
.section {
  padding: 5rem 0;
  position: relative;
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}
@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}

 /* Bootstrap media breakpoints */
/*  X-Small devices (portrait phones, less than 576px)
  No media query for xs since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { ... }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { ... }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { ... }

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { ... }

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { ... }




`
export const wrapRootElement = ({ element }) => {
  return (
    <>
      <GlobalStyle />
      <GatsbyProvider>{element}</GatsbyProvider>
    </>
  )
}
