// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-audio-albums-js": () => import("./../../../src/pages/audioAlbums.js" /* webpackChunkName: "component---src-pages-audio-albums-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-translation-authors-js": () => import("./../../../src/pages/translation/authors.js" /* webpackChunkName: "component---src-pages-translation-authors-js" */),
  "component---src-pages-translation-books-js": () => import("./../../../src/pages/translation/books.js" /* webpackChunkName: "component---src-pages-translation-books-js" */),
  "component---src-pages-translation-books-year-js": () => import("./../../../src/pages/translation/booksYear.js" /* webpackChunkName: "component---src-pages-translation-books-year-js" */),
  "component---src-pages-translation-magazines-js": () => import("./../../../src/pages/translation/magazines.js" /* webpackChunkName: "component---src-pages-translation-magazines-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-pages-writing-writing-books-js": () => import("./../../../src/pages/writing/writingBooks.js" /* webpackChunkName: "component---src-pages-writing-writing-books-js" */),
  "component---src-pages-writing-writing-magazines-js": () => import("./../../../src/pages/writing/writingMagazines.js" /* webpackChunkName: "component---src-pages-writing-writing-magazines-js" */),
  "component---src-templates-audio-album-js": () => import("./../../../src/templates/audioAlbum.js" /* webpackChunkName: "component---src-templates-audio-album-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-book-js": () => import("./../../../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-book-year-js": () => import("./../../../src/templates/bookYear.js" /* webpackChunkName: "component---src-templates-book-year-js" */),
  "component---src-templates-magazine-js": () => import("./../../../src/templates/magazine.js" /* webpackChunkName: "component---src-templates-magazine-js" */),
  "component---src-templates-video-js": () => import("./../../../src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */),
  "component---src-templates-writing-book-js": () => import("./../../../src/templates/writingBook.js" /* webpackChunkName: "component---src-templates-writing-book-js" */),
  "component---src-templates-writing-magazine-js": () => import("./../../../src/templates/writingMagazine.js" /* webpackChunkName: "component---src-templates-writing-magazine-js" */)
}

